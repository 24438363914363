import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useIntl } from "react-intl";
import Link from "next/link";

import AddToCartIcon from "../assets/images/icons/add-to-cart.png";
import AddedToCartIcon from "../assets/images/icons/added-to-cart.png";
import { changeBasketCount } from "../redux/global.slice";
import { basket_req } from "../api/api";
import { addRemoveFromBasket } from "../helpers/basket.helper";
import { getCookie } from "../helpers/cookie.helpers";

const ProductItem = ({
  image,
  name,
  code,
  size,
  price,
  tag,
  oldPriceData,
  id,
  currency,
  changeSize,
  minPrice,
  maxPrice,
}) => {
  const [sizeState, setSizeState] = useState(null);
  const dispatch = useDispatch();
  const [basketData, setBasketData] = useState(null);

  const intl = useIntl();

  const priceData = price * tag;
  const priceOld = Math.round(priceData / 100);
  const newPrice = price - priceOld;

  const basket = useSelector((state) => state.global.basket);

  const basketDataReq = async () => {
    try {
      const basketReq = await basket_req(basket);
      setBasketData(basketReq);
      const data = getCookie("basket");

      if (basketReq) {
        dispatch(changeBasketCount(data?.length));
      }
    } catch (e) {}
  };

  const addBasket = () => {
    basketDataReq();
    addRemoveFromBasket(
      id,
      1,
      intl.messages.product_successfully_added,
      intl.messages.product_successfully_removed
    );
    // const dataFilter = basketData?.find((item) => item.id === id);
  };
  useEffect(() => {
    if (typeof size === "string") {
      setSizeState(JSON.parse(size ? size : null));
    } else {
      setSizeState(size);
    }
  }, [size]);

  const defaultImage =
    "https://via.placeholder.com/1000x1000/f3f3f3/f3f3f3/?text=.";
  return (
    <div className="product-item mb-40">
      <div className="product-image">
        <Link
          href={
            (`/product?id=${id}&changeSize=${changeSize}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
            `/product?id=${id}`)
          }
        >
          <a>
            {image ? (
              <img
                src={image}
                alt={name}
              />
            ) : (
              <img
                src={defaultImage}
                alt={name}
              />
            )}
            {tag > 0 && <span className="tag">-{tag}%</span>}
          </a>
        </Link>

        {!basketData ? (
          <button
            className="cart-button"
            onClick={addBasket}
          >
            <span>{intl.messages.addBasket}</span>
            <img
              src={AddToCartIcon.src}
              alt="Add To Cart"
            />
          </button>
        ) : (
          <div className="cart-button">
            <span>{intl.messages.productAdded}</span>
            <img
              src={AddedToCartIcon.src}
              alt=""
            />
          </div>
        )}
      </div>
      {name && (
        <Link
          className="product-name mb-10"
          href={
            (`/product?id=${id}&changeSize=${changeSize}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
            `/product?id=${id}`)
          }
        >
          <a className="product-name">{name}</a>
        </Link>
      )}
      <div className="flex flex-ai-end flex-jc-between p-20 pl-10">
        <div>
          <ul className="product-info">
            {code && (
              <li>
                {intl.messages.code}: {code}
              </li>
            )}
            {sizeState?.[0]?.name || sizeState?.name ? (
              <li>
                {intl.messages.size}: {sizeState?.[0]?.name || sizeState?.name}
              </li>
            ) : null}
          </ul>
        </div>
        <div className="product-prices">
          {
            <div className="product-price">
              {tag ? newPrice : price} {currency}
            </div>
          }

          {oldPriceData && price && tag > 0 && (
            <div className="product-oldPrice">
              {price} {currency}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
