import { useRouter } from "next/router";
import React from "react";

const CategoryItem = ({ id, title, description, image }) => {
  const router = useRouter();

  const goTo = () => {
      router.push(`/shop/1?&item=${id}`);
  }

  return (
    <div
      className={`category-item large flex flex-ai-end`}
      onClick={goTo}
    >
        <img src={image} alt=""/>
      <div className="category-item-content">
        <div className="category-name">{title}</div>
        <div className="category-description">{description}</div>
      </div>
    </div>
  );
};

export default CategoryItem;
