import { basket_req } from "../api/api";
import { deleteCookie, getCookie, setCookie } from "./cookie.helpers";
import {toast} from "react-toastify";


export function addRemoveFromBasket(id, count, addMessage, removeMessage) {
  let existingBasket = getCookie("basket");

  if (!existingBasket) {
    setCookie("basket", JSON.stringify([id]));
    setCookie(
      "productsCount",
      JSON.stringify({
        [id]: count,
      })
    );
    toast.success(addMessage);
    return;
  }

  let productsCount = getCookie("productsCount");

  try {
    existingBasket = JSON.parse(existingBasket);
    productsCount = JSON.parse(productsCount);

    const indexInBasket = existingBasket.indexOf(id);

    if (indexInBasket === -1) {
      productsCount[id] = count;
      existingBasket.push(id);

      setCookie("basket", JSON.stringify(existingBasket));
      setCookie("productsCount", JSON.stringify(productsCount));
      toast.success(addMessage);
    } else {
      if (existingBasket.length === 1) {
        const basketDataReq = async () => {
          try {
            const basketReq = await basket_req(existingBasket);
            const data = getCookie("basket");
          } catch (e) {}
        };
        basketDataReq();
        toast.success(removeMessage);
        deleteCookie("basket");
        deleteCookie("productsCount");
        window.location.reload();
        return;
      }

      delete productsCount[id];

      existingBasket.splice(indexInBasket, 1);

      toast.success(removeMessage);
      setCookie("basket", JSON.stringify(existingBasket));
      setCookie("productsCount", JSON.stringify(productsCount));
    }
  } catch (e) {
    deleteCookie("basket");
    deleteCookie("productsCount");
  }
}

export function incrementProduct(id) {
  let productsCount = getCookie("productsCount");

  if (!productsCount) {
    return;
  }

  try {
    productsCount = JSON.parse(productsCount);

    productsCount[id]++;
    setCookie("productsCount", JSON.stringify(productsCount));
  } catch (e) {
    console.error("incrementProduct Error", e);
    deleteCookie("basket");
    deleteCookie("productsCount");
  }
}

export function decrementProduct(id) {
  let productsCount = getCookie("productsCount");

  if (!productsCount) {
    return;
  }

  try {
    productsCount = JSON.parse(productsCount);

    productsCount[id]--;

    if (productsCount[id] === 0) {
      delete productsCount[id];
    }

    setCookie("productsCount", JSON.stringify(productsCount));
  } catch (e) {
    console.error("decrementProduct Error", e);
    deleteCookie("basket");
    deleteCookie("productsCount");
  }
}
export const changeCountForProduct = () => {
  const countObj = { ...state.productsCount };
  countObj[action.payload.productId] = action.payload.count;

  setCookie("productsCount", JSON.stringify(countObj));
  state.productsCount = countObj;
};
