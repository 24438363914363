import { useMemo } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";

const Page = ({ meta, jsonLd, children }) => {
  const router = useRouter();

  //! Url
  const url = process.env.host + router.asPath;

  //! Title
  const title = useMemo(
    () => meta?.title || "Handle Brand Armenia",
    [meta?.title]
  );

  //! Description
  const description = useMemo(
    () => meta?.description || "Handle Brand Armenia",
    [meta?.description]
  );

  //! Image
  const image = useMemo(
    () => ({
      url: meta?.image || process.env.host + "/handle-logo.png",
      alt: title,
    }),
    [meta?.image]
  );

  //! Ld Json
  const ldJson = jsonLd || {
    "@context": "http://schema.org/",
    "@type": "Organization",
    name: "Handle Brand Armenia",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Yerevan, Buzand 1/3",
      addressLocality: "Yerevan",
      addressRegion: "Yerevan",
    },
    telephone: "+374 33 58 58 50",
  };

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        type="website"
        openGraph={{
          url: url,
          title: title,
          description: description,
          images: [image],
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        />
      </Head>
      {children}
    </>
  );
};

export default Page;
